import React, { FunctionComponent } from 'react'

type Props = {}

import 'normalize.css'
import '../styles/main.css'
const NotFoundPage: FunctionComponent<Props> = ({}) => {
	return <div></div>
}

export default NotFoundPage
